<template>
	<secondary-page-layout :title="$t('pay')" :displayedNavigation="true">
		<div class="wrap-form-payment">
			<div class="form-payment-header">
				<p class="title">{{$t('payContractTitle_5')}} №{{ policyID }} </p>
				<p class="subtitle">{{$t('payContractTitle_6')}} {{ issueDate }} г.</p>
			</div>
      <div class="form-group payment-page" style="padding: 0">
        <input-field-list>
          <p>
            <label>{{$t('payContractTitle_1')}}</label><br>
            <span class="description">{{form.product}}</span>
          </p>

          <p>
            <label>{{$t('payContractTitle_2')}}</label><br>
            <span class="description">{{form.policyholder}}</span>
          </p>

          <p>
            <label>{{$t('payContractTitle_3')}}</label><br>
            <span class="description">{{formInsureds}}</span>
          </p>

          <p>
            <label>{{$t('payContractTitle_4')}}</label><br>
            <span class="description">{{formObjects}}</span>
          </p>
        </input-field-list>
      </div>

	   	</div>
	   	<div class="wrap-total__payment-treaty">
	   		<div class="info-wrap">
	   			<span class="info">Страховая премия</span>
	   			<span class="price">{{ policyAmount | priceFormat }} ₸</span>
	   		</div>
	   		<div class="total-wrap">
	   			<span class="info">Итого</span>
	   			<span class="price">{{ policyAmount | priceFormat }} ₸</span>
	   		</div>
	   		<button class="primary-btn" @click="pay">{{$t('pay')}}</button>
	   	</div>
	</secondary-page-layout>
</template>
<script>
	import InputField from "@/components/BuyPolicy/InputField";
	import InputFieldList from "@/components/BuyPolicy/InputFieldList";
  import moment from "moment";
  import ePayPayment from "../../mixin/ePayPayment";

	export default {
    mixins: [ePayPayment],
		components: {
		 	InputField,
		 	InputFieldList
		},
    async mounted() {
      const loader = this.$loading.show();
      try{
        const {data} = await window.axios.get(`/policies/to-payment/${this.policyID}`);
        this.policy = data;

        this.form.product = this.policy.productName
        this.form.policyholder = this.policy.holder.title
        this.form.insured = this.policy.insured
        this.form.object = this.policy.object
      }catch (e) {
        const message = e?.response?.data?.message || e?.message || 'Произошла ошибка, повторите попытку позднее'
      }finally {
        loader.hide();
      }
    },
    computed: {
      authUser(){
        return this.$store.getters['authUser']
      },
      issueDate(){
        if(!this.policy) {
          return;
        }

        moment.locale(this.$store.getters.selectedLanguage)

        const momentDate = moment(this.policy.issueDate)
        // console.log(momentDate)
        return momentDate.format('DD MMMM YYYY')
      },
      formInsureds() {
        if(!this.policy) {
          return;
        }
        if(this.policy.insureds && this.policy.insureds.length) {
          return this.policy.insureds.map(item => `${item.title}`).join(', ')
        }
      },
      formObjects() {
        if(!this.policy) {
          return;
        }
        if(this.policy.objects && this.policy.objects.length) {
          return this.policy.objects.join(', ')
        }
      },
      policyAmount(){
        if(!this.policy) {
          return;
        }

        return this.policy.amount;
      }
    },
    data(){
			return{
        policyID: this.$route.params.id,
        policy: null,
				form:{
					product: '',
					policyholder: '',
					insured: '',
					object: '',
				}
			}
		},
    methods: {
      async pay() {
        let paymentAuth;

        const loader = this.$loading.show();

        try {
          const { data } = await window.axios.post(`/policies/auth/${this.policyID}`, { amount: this.policy.amount })
          paymentAuth = data
        } catch (e) {
          console.log(e)
        }

        try{
          await this.freePayment(paymentAuth, this.authUser)
        }catch (e) {
          console.log(e)
        }finally {
          loader.hide();
        }
      }
    }
	}
</script>

<style lang="scss">
.form-group.payment-page p {
  padding: 10px 0;
  border-bottom: 1px solid #C8C7CC;
  margin-bottom: 0 !important;
  span.description {
    color: #99A3B3;
  }
}
.pb-80 {
  padding-bottom: 80px;
}
	.title-wrap {
	padding: 0px 16px;
	align-items: center;
	margin-bottom: 20px;
	  .title {
	    font-weight: bold;
	    font-size: 14px;
	  }
  	}

	.wrap-form-payment{
		padding: 0px 16px;
		.form-payment-header{
			padding: 0px 10px;
			.title{
				font-family: 'Manrope';
				font-weight: 700;
				font-size: 16px;
				line-height: 18px;
				color: #071222;
				margin-bottom: 10px;
			}
			.subtitle{
				font-family: 'Manrope';
				font-size: 14px;
				font-weight: 300;
				color: #99A3B3;
				margin-bottom: 16px;
			}
		}
		
		.input-field {
		position: relative;
		display: flex;
		align-items: center;
		padding: 12px 0;
		height: 64px;
		border-bottom: 1px solid #C8C7CC;
		margin-bottom: 12px !important;
		}
	}

	.wrap-total__payment-treaty{
		position: fixed;
    	bottom: 0px;
    	left: 0px;
    	z-index: 600;
    	width: 100%;
    	background-color: #fff;
    	padding: 16px;
    	.info-wrap, .total-wrap{
    		display: flex;
    		align-items: center;
    		justify-content: space-between;
    	}
    	.primary-btn{
    		width: 100%;
    	}
    	.info-wrap{
    		margin-bottom: 8px;
    		.info{
    			font-family: 'Manrope';
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: #071222;
				width: 80%;
    		}
    		.price{
    			font-family: 'Manrope';
				font-weight: 700;
				font-size: 14px;
				line-height: 16px;
				text-align: right;
				color: #071222;
    		}
    	}
    	.total-wrap{
    		padding-bottom: 8px;
    		border-bottom: 1px solid rgba(0, 0, 0, 0.0001);
    		margin-bottom: 8px;
    		.info{
    			font-family: 'Manrope';
				font-weight: 700;
				font-size: 16px;
				line-height: 18px;
				color: #071222;
    		}
    		.price{
    			font-family: 'Manrope';
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 18px;
				text-align: right;
				color: #2AA65C;
    		}
    	}
	}
</style>